import { statesConstants } from "../constants/states.constants";

const start = () => {
    return {type: statesConstants.STATES_START, payload: {loading: true}};
};

const stop = () => {
    return {type: statesConstants.STATES_STOP, payload: {loading: false}};
};

const states = (res) => {
    return {type: statesConstants.STATES_STATES, payload: {list: res}};
};

const clear = () => {
    return {type: statesConstants.STATES_CLEAR, payload: {clear: null}};
};

export const statesSelectors = {
    start,
    stop,
    states,
    clear,
}