import { awardConstants } from "../constants";

const start = () => {
    return {type: awardConstants.START, payload: {loading: true}};
};

const stop = () => {
    return {type: awardConstants.STOP, payload: {loading: false}};
};

const list = (res) => {
    return {type: awardConstants.AWARD, payload: {list: res}};
};

const add = (res) => {
    return {type: awardConstants.ADD, payload: {add: res}};
};

const update = (res) => {
    return {type: awardConstants.UPDATE, payload: {update: res}};
};

const remove = (res) => {
    return {type: awardConstants.REMOVE, payload: {remove: res}};
};

const clear = () => {
    return {type: awardConstants.CLEAR, payload: {list: []}};
};

export const awardSelectors = {
    start,
    stop,
    list,
    add,
    update,
    remove,
    clear
}