import { statesConstants } from "../constants/states.constants";

const initialState = {loading: false, states: []};

export const statesReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case statesConstants.STATES_START:
            return {
                ...state,
                loading: true
            };
        case statesConstants.STATES_STOP:
            return {
                ...state,
                loading: false
            };
        case statesConstants.STATES_STATES:
            return {
                ...state,
                states: payload.list
            };
        case statesConstants.STATES_CLEAR:
            return {
                ...state,
                states: []
            };
        default:
            return state
    }
};