import { locationConstants } from "../constants";

const start = () => {
    return {type: locationConstants.LOCATION_PROCESS_START, payload: {loading: true}};
};

const stop = () => {
    return {type: locationConstants.LOCATION_PROCESS_STOP, payload: {loading: false}};
};

const list = (res) => {
    return {type: locationConstants.LOCATION_LIST, payload: {list: res}};
};

const add = (res) => {
    return {type: locationConstants.LOCATION_ADD, payload: {add: res}};
};

const update = (res) => {
    return {type: locationConstants.LOCATION_UPDATE, payload: {update: res}};
};

const remove = (res) => {
    return {type: locationConstants.LOCATION_REMOVE, payload: {remove: res}};
};

const clear = (res) => {
    return {type: locationConstants.LOCATION_CLEAR, payload: {clear: res}};
};

export const locationSelectors = {
    start,
    stop,
    list,
    add,
    update,
    remove,
    clear
}