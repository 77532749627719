import { commonConstants } from '../constants/common.constants';

export const configReducer = (state = {config: null}, {type, payload}) => {
    switch (type) {
        case commonConstants.COMMON_CONFIG:
            //console.log('payload.data', payload.data)
            return {
                type: commonConstants.COMMON_CONFIG,
                config: payload.data
            };
        default:
            return state
    }
};