import { favoriteConstants } from "../constants";


const start = () => {
    return {type: favoriteConstants.START, payload: {loading: true}};
};

const stop = () => {
    return {type: favoriteConstants.STOP, payload: {loading: false}};
};

const list = (res) => {
    return {type: favoriteConstants.FAVORITE, payload: {list: res}};
};

const add = (res) => {
    return {type: favoriteConstants.ADD, payload: {add: res}};
};

const update = (res) => {
    return {type: favoriteConstants.UPDATE, payload: {update: res}};
};

const remove = (res) => {
    return {type: favoriteConstants.REMOVE, payload: {remove: res}};
};

const clear = () => {
    return {type: favoriteConstants.CLEAR, payload: {list: []}};
};

export const favoriteSelectors = {
    start,
    stop,
    list,
    add,
    update,
    remove,
    clear
}