import { unavailabilityConstants } from "../constants/unavailability.constants";

const start = () => {
    return {type: unavailabilityConstants.START, payload: {loading: true}};
};

const stop = () => {
    return {type: unavailabilityConstants.STOP, payload: {loading: false}};
};

const list = (res) => {
    return {type: unavailabilityConstants.UNAVAILABILITY, payload: {list: res}};
};

const add = (res) => {
    return {type: unavailabilityConstants.ADD, payload: {add: res}};
};

const update = (res) => {
    return {type: unavailabilityConstants.UPDATE, payload: {update: res}};
};

const remove = (res) => {
    return {type: unavailabilityConstants.REMOVE, payload: {remove: res}};
};

const clear = () => {
    return {type: unavailabilityConstants.CLEAR, payload: {list: []}};
};

export const unavailabilitySelectors = {
    start,
    stop,
    list,
    add,
    update,
    remove,
    clear
}