import { notificationConstants } from "../constants";

const start = () => {
    return {type: notificationConstants.NOTIFICATION_PROCESS_START, payload: {loading: true}};
};

const stop = () => {
    return {type: notificationConstants.NOTIFICATION_PROCESS_STOP, payload: {loading: false}};
};

const list = (res) => {
    return {type: notificationConstants.NOTIFICATION_LIST, payload: {list: res}};
};

const more = (res) => {
    return {type: notificationConstants.NOTIFICATION_MORE, payload: {more: res}};
};

const read = (res) => {
    return {type: notificationConstants.NOTIFICATION_READ, payload: {read: res}};
};

const remove = (res) => {
    return {type: notificationConstants.NOTIFICATION_REMOVE, payload: {remove: res}};
};

const clear = (res = null) => {
    return {type: notificationConstants.NOTIFICATION_CLEAR, payload: {clear: res}};
};

export const notificationSelectors = {
    start,
    stop,
    list,
    more,
    read,
    remove,
    clear,
}