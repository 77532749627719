import { awardConstants } from "../constants";


const initialState = {loading: false, awards: []};

export const awardReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case awardConstants.START:
            return {
                ...state,
                loading: true
            };
        case awardConstants.STOP:
            return {
                ...state,
                loading: false
            };
        case awardConstants.AWARD:
            return {
                ...state,
                awards: payload.list.items,
                pagination: payload.list.pagination
            };
        case awardConstants.ADD: 
            return {
                ...state,
                awards: [...state.awards, payload.add],
            };
        case awardConstants.UPDATE:
            const index = state.awards.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                awards: [...state.awards.slice(0,index),
                    payload.update,
                    ...state.awards.slice(index+1)
                ],
            };
        case awardConstants.REMOVE:
            return {
                awards: [...state.awards.filter( val => parseInt(val.id) !== parseInt(payload.remove) )]
            };
        case awardConstants.CLEAR:
            return {
                ...state,
                awards: [],
                pagination: null
            };
        default:
            return state
    }
};