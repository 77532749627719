import { subjectConstants } from "../constants";

const initialState = {loading: false, subjects: []};

export const subjectReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case subjectConstants.START:
            return {
                ...state,
                loading: true
            };
        case subjectConstants.STOP:
            return {
                ...state,
                loading: false
            };
        case subjectConstants.SUBJECT:
            return {
                ...state,
                subjects: payload.list.items,
                pagination: payload.list.pagination
            };
        case subjectConstants.ADD: 
            return {
                ...state,
                subjects: [...state.subjects, payload.add],
            };
        case subjectConstants.UPDATE:
            const index = state.subjects.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                subjects: [...state.subjects.slice(0,index),
                    payload.update,
                    ...state.subjects.slice(index+1)
                ],
            };
        case subjectConstants.REMOVE:
            return {
                subjects: [...state.subjects.filter( val => parseInt(val.id) !== parseInt(payload.remove))]
            };
        case subjectConstants.CLEAR:
            return {
                ...state,
                subjects: [],
                pagination: null
            };
        default:
            return state
    }
};