import {commonConstants} from '../constants';

const categories = (res) => {
    return {type: commonConstants.COMMON_CATEGORIES, payload: {data: res}};
};

const userCategories = (res) => {
    return {type: commonConstants.COMMON_USER_CATEGORIES, payload: {data: res}};
};

const skills = (res) => {
    return {type: commonConstants.COMMON_SKILLS, payload: {data: res}};
};

const countries = (res) => {
    return {type: commonConstants.COMMON_COUNTRIES, payload: {data: res}};
};

const questions = (res) => {
    return {type: commonConstants.COMMON_QUESTIONS, payload: {data: res}};
};

const platforms = (res) => {
    return { type: commonConstants.COMMON_PLATFORMS, payload: { data: res } };
};

const config = (res) => {
    return { type: commonConstants.COMMON_CONFIG, payload: { data: res } };
};

const content = (res) => {
    return { type: commonConstants.COMMON_CONTENT, payload: { data: res } };
};

const pages = (res) => {
    return { type: commonConstants.COMMON_PAGES, payload: { data: res } };
};

const pageStart = () => {
    return {type: commonConstants.COMMON_PAGES_START, payload: {loading: true}};
};

const pageStop = () => {
    return {type: commonConstants.COMMON_PAGES_STOP, payload: {loading: false}};
};

export const commonSelectors = {
    categories,
    userCategories,
    skills,
    countries,
    questions,
    platforms,
    config,
    content,
    pages,
    pageStart,
    pageStop,
};
