import { educationConstants } from "../constants";

const initialState = {loading: false, educations: []};

export const educationReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case educationConstants.START:
            return {
                ...state,
                loading: true
            };
        case educationConstants.STOP:
            return {
                ...state,
                loading: false
            };
        case educationConstants.EDUCATION:
            return { 
                ...state,
                educations: payload.list.items,
                pagination: payload.list.pagination
            };
        case educationConstants.ADD: 
            return {
                ...state,
                educations: [...state.educations, payload.add],
            };
        case educationConstants.UPDATE:
            const index = state.educations.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                educations: [...state.educations.slice(0,index),
                    payload.update,
                    ...state.educations.slice(index+1)
                ],
            };
        case educationConstants.REMOVE:
            return {
                educations: [...state.educations.filter( val => parseInt(val.id) !== parseInt(payload.remove) )]
            };
        case educationConstants.CLEAR:
            return {
                ...state,
                educations: [],
                pagination: null
            };
        default:
            return state
    }
};