export const commonConstants = {
    COMMON_CATEGORIES: 'common:categories',
    COMMON_USER_CATEGORIES: 'common:user:categories',
    COMMON_SKILLS: 'common:skills',
    COMMON_COUNTRIES: 'common:countries',
    COMMON_QUESTIONS: 'common:questions',
    COMMON_PLATFORMS: 'common:platforms',
    COMMON_CONFIG: 'common:config',
    COMMON_CONTENT: 'common:content',
    COMMON_PAGES: 'common:pages',
    COMMON_PAGES_START: 'common:pages:start',
    COMMON_PAGES_STOP: 'common:pages:stop',
};
