import { commonConstants } from "../constants";

const initialState = {loading: false, data: [], view: ''};

export const pagesReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case commonConstants.COMMON_PAGES_START:
            return {
                ...state,
                loading: true
            };
        case commonConstants.COMMON_PAGES_STOP:
            return {
                ...state,
                loading: false
            };
        case commonConstants.COMMON_PAGES:
            return {
                ...state,
                data: payload.data
            };
        default:
            return state
    }
};