import { experienceConstants } from "../constants";


const start = () => {
    return {type: experienceConstants.START, payload: {loading: true}};
};

const stop = () => {
    return {type: experienceConstants.STOP, payload: {loading: false}};
};

const list = (res) => {
    return {type: experienceConstants.EXPERIENCE, payload: {list: res}};
};

const add = (res) => {
    return {type: experienceConstants.ADD, payload: {add: res}};
};

const update = (res) => {
    return {type: experienceConstants.UPDATE, payload: {update: res}};
};

const remove = (res) => {
    return {type: experienceConstants.REMOVE, payload: {remove: res}};
};

const clear = () => {
    return {type: experienceConstants.CLEAR, payload: {list: []}};
};

export const experienceSelectors = {
    start,
    stop,
    list,
    add,
    update,
    remove,
    clear
}