import { childConstants } from "../constants";


const start = () => {
    return {type: childConstants.START, payload: {loading: true}};
};

const stop = () => {
    return {type: childConstants.STOP, payload: {loading: false}};
};

const list = (res) => {
    return {type: childConstants.CHILD, payload: {list: res}};
};

const add = (res) => {
    return {type: childConstants.ADD, payload: {add: res}};
};

const update = (res) => {
    return {type: childConstants.UPDATE, payload: {update: res}};
};

const remove = (res) => {
    return {type: childConstants.REMOVE, payload: {remove: res}};
};

const clear = () => {
    return {type: childConstants.CLEAR, payload: {list: []}};
};

export const childSelectors = {
    start,
    stop,
    list,
    add,
    update,
    remove,
    clear
}