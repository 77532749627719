import firebase from "firebase"
const config = {
    apiKey: "AIzaSyCkHMa11qI_BEJynTuUbss9Oh6BzMTYeA8",
    authDomain: "k12-tutor-connect.firebaseapp.com",
    databaseURL: "https://k12-tutor-connect.firebaseio.com",
    projectId: "k12-tutor-connect",
    storageBucket: "k12-tutor-connect.appspot.com",
    messagingSenderId: "1012971229434",
    appId: "1:1012971229434:web:1e27db041def2f3024f943",
    serviceAccountId: "firebase-adminsdk-stw2u@k12-tutor-connect-setup.iam.gserviceaccount.com",
    measurementId: "G-ZZ0T76NTJ0"
};

const app = firebase.initializeApp(config);
const storage = app.storage();
const db = app.database();

export const firebaseConfig = {
    firebase,
    app,
    storage,
    db
};