import { favoriteConstants } from "../constants";


const initialState = {loading: false, favorites: []};

export const favoriteReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case favoriteConstants.START:
            return {
                ...state,
                loading: true
            };
        case favoriteConstants.STOP:
            return {
                ...state,
                loading: false
            };
        case favoriteConstants.FAVORITE:
            return {
                ...state,
                favorites: payload.list.items,
                pagination: payload.list.pagination
            };
        case favoriteConstants.ADD: 
            return {
                ...state,
                favorites: [...state.favorites, payload.add],
            };
        case favoriteConstants.UPDATE:
            const index = state.favorites.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                favorites: [...state.favorites.slice(0,index),
                    payload.update,
                    ...state.favorites.slice(index+1)
                ],
            };
        case favoriteConstants.REMOVE:
            return {
                favorites: [...state.favorites.filter( val => parseInt(val.id) !== parseInt(payload.remove) )]
            };
        case favoriteConstants.CLEAR:
            return {
                ...state,
                favorites: [],
                pagination: null
            };
        default:
            return state
    }
};