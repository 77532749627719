import { childConstants } from "../constants";


const initialState = {loading: false, childs: [], pagination: null};

export const childReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case childConstants.START:
            return {
                ...state,
                loading: true
            };
        case childConstants.STOP:
            return {
                ...state,
                loading: false
            };
        case childConstants.CHILD:
            return {
                ...state,
                childs: payload.list.items,
                pagination: payload.list.pagination
            };
        case childConstants.ADD:
            return {
                ...state,
                childs: [...state.childs, payload.add],
            };
        case childConstants.UPDATE:
            const index = state.childs.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                childs: [...state.childs.slice(0,index),
                    payload.update,
                    ...state.childs.slice(index+1)
                ],
            };
        case childConstants.REMOVE:
            return {
                childs: [...state.childs.filter( val => parseInt(val.id) !== parseInt(payload.remove) )]
            };
        case childConstants.CLEAR:
            return {
                ...state,
                childs: [],
                pagination: null
            };
        default:
            return state
    }
};