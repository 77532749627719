import { notificationConstants } from "../constants";


const initialState = {loading: false, notifications: null};

export const notificationReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case notificationConstants.NOTIFICATION_PROCESS_START:
            return {
                ...state,
                loading: true
            };
        case notificationConstants.NOTIFICATION_PROCESS_STOP:
            return {
                ...state,
                loading: false
            };
        case notificationConstants.NOTIFICATION_LIST:
            return {
                ...state,
                notifications: payload.list.items,
                pagination: payload.list.pagination
            };
        case notificationConstants.NOTIFICATION_MORE:
            return {
                ...state,
                notifications: [...state.notifications, payload.more],
            };
        case notificationConstants.NOTIFICATION_REMOVE:
            return {
                notifications: [...state.notifications.filter( val => parseInt(val.message_id) !== parseInt(payload.remove) )]
            };
        case notificationConstants.NOTIFICATION_READ:
            const index = state.notifications.findIndex(f => parseInt(f.message_id) === parseInt(payload.read));
            const object = state.notifications;
            object[index].status = 'Read';
            object[index].seen = true;
            return {
                ...state,
                notifications: [...state.notifications.slice(0,index),
                    object[index],
                    ...state.notifications.slice(index+1)
                ],
            };
        case notificationConstants.NOTIFICATION_CLEAR:
            return {};
        default:
            return state
    }
}